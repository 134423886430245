import * as React from "react"

const Testimonial = () => (   
    <div className="min-w-screen flex items-center justify-center py-5">
        <div className="w-full bg-white px-5 py-16 md:py-24 text-gray-800">
            <div className="w-full max-w-6xl mx-auto">
                <div className="text-center max-w-xl mx-auto">
                    <p className="section-title">What people are saying.</p>
                    
                </div>
                <div className="-mx-3 md:flex items-start">
                    <div className="px-3 md:w-1/3">
                        <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                                <div className="flex-grow pl-3">
                                    <h6 className="tetimnonial-name">William Maynard</h6>
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>I just started and got 24 clients happy with the price and the service! Great help, great turnaround time on requests. thanks million times	<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                            </div>
                        </div>
                        <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                                <div className="flex-grow pl-3">
                                    <h6 className="tetimnonial-name">Robert H.</h6>
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Wanted to add how beautiful the site is
                                now - so clear and easy to navigate - it’s
                                indeed like Heaven on Earth. But don’t
                                ascend yet - I need you here to host my
                                website a bit longer.<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="px-3 md:w-1/3">
                        <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                                <div className="flex-grow pl-3">
                                    <h6 className="tetimnonial-name">Connor F.</h6>
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>I have had accounts with other web hosts, including GoDaddy and 1and1. I can honestly say that you offer the optimal solution for any web developer. Thank you!	<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                            </div>
                        </div>
                        <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                                <div className="flex-grow pl-3">
                                    <h6 className="tetimnonial-name">Furanku M.</h6>
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>To be honest, when I was searching for a GoDaddy replacement, I did a lot of looking around before I finally came across you. This has been the best hosting experience I’ve ever had!<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="px-3 md:w-1/3">
                        <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                                <div className="flex-grow pl-3">
                                    <h6 className="tetimnonial-name">Ryan M.</h6>
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Just got an account today and I’m already in love with it! A wonder- ful-looking panel and wonderful options!<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                            </div>
                        </div>
                        <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                            <div className="w-full flex mb-4 items-center">
                                <div className="flex-grow pl-3">
                                    <h6 className="tetimnonial-name">Kris Stanton.</h6>
                                </div>
                            </div>
                            <div className="w-full">
                                <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem iusto, explicabo, cupiditate quas totam!<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)


export default Testimonial
