import * as React from "react"

import Navbar from "../components/navbar"

const Menu = () => (
    <div>
        <Navbar/> 
    </div>
)


export default Menu
