import * as React from "react"

import { Link } from "gatsby"

const Pricing = () => (
    <div className="sub-section-wrap py-32">
        <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-10">
            <div className="text-center mx-auto">
                <p className="section-title">Our completely scalable Web Hosting Plans</p>
                <p className="section-after-title">Offer outstanding-quality web hosting solutions at reduced prices and we give you a ton of charge-free tools, which will help you build your website in minutes.</p>
            </div>
            <div className="w-full md:flex mb-5 gap-3">
                <div className="pricing-wrap">
                    <div className="w-full flex-grow">
                        <h3 className="pricing-title">Startup</h3>
                        <h3 className="pricing-price">$3.07<span className="text-lg">/mo</span></h3>
                        <p className="text-center font-bold mb-5">
                        <Link to="/" className="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i className="mdi mdi-arrow-right-thick ml-1"></i></Link>
                        </p>
                        <ul className="text-sm px-5 mb-8">
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited Disk Space</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited Monthly Traffic</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 1 Hosted Domain(s)</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 100 E-mail Accounts</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 5 MySQL Databases</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 5 GB VPN Traffic</li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <Link to="/order-form/?plan=44029">
                        <button className="pricing-button">Select Plan</button>
                        </Link>
                    </div>
                </div>
                <div className="pricing-wrap pricing-active">
                    <div className="w-full flex-grow">
                        <h3 className="pricing-title title-active">Business</h3>
                        <h3 className="pricing-price">$4.29<span className="text-lg">/mo</span></h3>
                        <p className="text-center font-bold mb-5">
                        <Link to="/page-2/" className="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i className="mdi mdi-arrow-right-thick ml-1"></i></Link>
                        </p>
                        <ul className="text-sm px-5 mb-8">
                        <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited Disk Space</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited Monthly Traffic</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 5 Hosted Domain(s)</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 500 E-mail Accounts</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 20 MySQL Databases</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 15 GB VPN Traffic</li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <Link to="/order-form/?plan=1">
                        <button className="pricing-button-active">Select Plan</button>
                        </Link>
                    </div>
                </div>
                <div className="pricing-wrap">
                    <div className="w-full flex-grow">
                        <h3 className="pricing-title">Professional</h3>
                        <h3 className="pricing-price">$10.47<span className="text-lg">/mo</span></h3>
                        <p className="text-center font-bold mb-5">
                        <Link to="/page-2/" className="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i className="mdi mdi-arrow-right-thick ml-1"></i></Link>
                        </p>
                        <ul className="text-sm px-5 mb-8">
                        <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited Monthly Traffic</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited Hosted Domain(s)</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited E-mail Accounts</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited MySQL Databases</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 25 GB VPN Traffic</li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <Link to="/order-form/?plan=2">
                        <button className="pricing-button">Select Plan</button>
                        </Link>
                    </div>
                </div>
                <div className="pricing-wrap">
                    <div className="w-full flex-grow">
                        <h3 className="pricing-title">Enterprise</h3>
                        <h3 className="pricing-price">$15.21<span className="text-lg">/mo</span></h3>
                        <p className="text-center font-bold mb-5">
                        <Link to="/page-2/" className="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i className="mdi mdi-arrow-right-thick ml-1"></i></Link>
                        </p>
                        <ul className="text-sm px-5 mb-8">
                        <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited Monthly Traffic</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited Hosted Domain(s)</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited E-mail Accounts</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> Unlimited MySQL Databases</li>
                            <li className="leading-tight"><i className="mdi mdi-check-bold text-lg"></i> 50 GB VPN Traffic</li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <Link to="/order-form/?plan=44">
                        <button className="pricing-button">Select Plan</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
)


export default Pricing
