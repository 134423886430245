import * as React from "react"
import Menu from "../components/menu"
import Features from "../components/features"
import CTA from "../components/cta"
import Pricing from "../components/pricing"
import Testimonial from "../components/testimonial"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import Server from '../images/server.png'
import banner from "../images/home-banner.jpeg";
import SHB from "../images/sheephostingbay-web-hosting-services.png";
import SEO from "../components/seo"

const IndexPage = () => (
  <div>
    <SEO 
        title="Web Hosting - 2021&#x27;s Best Website Hosting" 
        description="Get your new website hosting service today.
        With a 30 day completely FREE trial period. No credit card information needed."
        image={SHB}
    />
    <div className="section-wrap">
          <div className="sub-section-wrap">
              <div className="hero-right-wrap">
                  <svg className="hero-svg-white" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                    <polygon points="50,0 100,0 50,100 0,100" />
                  </svg>
          
                  <Menu />
          
                  <main className="hero-main-wrap">
                      <div className="sm:text-center lg:text-left">
                          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-7xl">
                          <span className="block xl:inline">Get your new </span>
                          <span className="block text-indigo-600 xl:inline">website hosting </span>
                          <span className="block xl:inline">service today.</span>
                          </h1>
                          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-3xl lg:mx-0">
                          With a 30 day completely FREE trial period. No credit card information needed.
                          </p>
                          <p className="pt-2 tracking-wide">
                              Starting at only &nbsp;<br/>
                              <span className="text-gray-400 align-top">$ </span>
                              <span className="text-6xl font-bold">3.07</span>
                              <span className="text-gray-400 font-medium">/ month</span>
                          </p>
                          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                              <div className="rounded-md shadow">
                                  <a href="/order-form/" className="hero-btn-dark">
                                  Start FREE Trial
                                  </a>
                              </div>
                              <div className="mt-3 sm:mt-0 sm:ml-3">
                                  <a href="/website-hosting-comparison" className="hero-btn-light">
                                  Compare Plans
                                  </a>
                              </div>
                          </div>
                      </div>
                      
                  </main>
              </div>
          </div>

          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img 
                className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" 
                src={banner}
                alt="Sheephostingbay"
            />
          </div>
      </div>

      <Features/>
      <CTA/>
      <Pricing/>
      
      <div className="py-32 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

            <div className="mt-10">
                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-16 md:gap-y-10">
       
                    <div className="flex">
                        
                        <div className="ml-4 py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                            <p className="section-title">Choose your data center</p>
                            <p>
                            We are focused on offering customer–oriented web hosting solutions. That is the reason why we offer you the opportunity to pick your data center location, in line with your Internet marketing tactics. 
                            <br/><br/>
                            The nearer your websites are to your target visitors, the faster web site loading speeds you’ll be able to offer them and the higher you’ll rank in local search results.
                            </p>
                            <br/><br/>
                            <p className="font-bold underline">A risk–free hosting service</p>
                            <p>
                            To top it all off, our hosting service is 100% risk–free. We offer you a 99.9% server uptime guarantee, which implies that your websites will be online irrespective of what happens with our web hosting servers.<br/><br/>
                            </p>
                            <img src="https://sheephostingbay.duoservers.com/template2/img/risk-free-hosting-service-02.webp" alt="risk free" />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="">
                            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                                <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-pink-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                                <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                                <div className="max-w-md mx-auto">
                                    <div>
                                    <img src={Server} className="w-32" alt="server" />
                                    </div>
                                    <div className="divide-y divide-gray-200">
                                    <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                        <p>Our data center facilities comply with all industry standards.</p>
                                        <ul className="list-disc space-y-2">
                                        <li className="flex items-start">
                                            <span className="h-6 flex items-center sm:h-7">
                                            <svg className="flex-shrink-0 h-5 w-5 text-cyan-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            </span>
                                            <p className="ml-2">
                                            US
                                            <code className="text-sm font-bold text-gray-900">Steadfast, Chicago, IL</code> 
                                            </p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="h-6 flex items-center sm:h-7">
                                            <svg className="flex-shrink-0 h-5 w-5 text-cyan-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            </span>
                                            <p className="ml-2">
                                            UK
                                            <code className="text-sm font-bold text-gray-900">Maidenhead, Berkshire, UK</code>
                                            </p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="h-6 flex items-center sm:h-7">
                                            <svg className="flex-shrink-0 h-5 w-5 text-cyan-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            </span>
                                            <p className="ml-2">
                                            AU
                                            <code className="text-sm font-bold text-gray-900">Amaze, Sydney, Australia</code>
                                            </p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="h-6 flex items-center sm:h-7">
                                            <svg className="flex-shrink-0 h-5 w-5 text-cyan-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            </span>
                                            <p className="ml-2">
                                            BG
                                            <code className="text-sm font-bold text-gray-900">Telepoint, Sofia, Bulgaria</code>
                                            </p>
                                        </li>
                                        <li className="flex items-start">
                                            <span className="h-6 flex items-center sm:h-7">
                                            <svg className="flex-shrink-0 h-5 w-5 text-cyan-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                            </svg>
                                            </span>
                                            <p className="ml-2">
                                            Finland
                                            <code className="text-sm font-bold text-gray-900">Ficolo/Pori, Finland</code>
                                            </p>
                                        </li>
                                       
                                        </ul>
                                        <p>No matter which data center you select, you’ll get a 99.9% network uptime guarantee with all cloud web hosting packages.</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        

                    </div>
                </dl>
            </div>
        </div>
    </div>

      <Testimonial/>
      <Subscribe/>
      <Footer/>
    </div>
)

export default IndexPage
