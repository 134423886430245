import * as React from "react"

import DBackup from '../images/data-storage-device.png'
import UnliDisk from '../images/unlimited.png'
import Check from '../images/check.png'
import CMS from '../images/cms.png'
import Secure from '../images/secure.png'

const Features = () => (
    
    <div className="py-32 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
                <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Features</h2>
                <p className="section-title">
                    Pick your outstanding-quality web hosting solutions
                </p>
                <p className="section-after-title">
                    We offer you over 300 charge-free web design themes and a 99.9% uptime guarantee.
                </p>
            </div>

            <div className="mt-10">
                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                            </svg>
                            </div>
                        </div>
                        <div className="ml-4">
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                            Web Hosting Control Panel
                            </dt>
                            <dd className="mt-2 text-base text-gray-500">
                            All the helpful website administration tools you may need in one single location
                            </dd>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <img src={DBackup} alt="Database Backups" className="w-5 m-0"/>
                            
                            </div>
                        </div>
                        <div className="ml-4">
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                            Data Backups
                            </dt>
                            <dd className="mt-2 text-base text-gray-500">
                            Your web site content could be restored whenever you want
                            </dd>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            
                            <img src={UnliDisk} alt="Unlimited Disk Space" className="w-5 m-0"/>
                            </div>
                        </div>
                        <div className="ml-4">
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                            Unlimited Disk Space
                            </dt>
                            <dd className="mt-2 text-base text-gray-500">
                            By using our website hosting packages, you will never concern yourself with hard drive space.
                            </dd>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            
                            <img src={Check} alt="Guarantee" className="w-5 m-0"/>
                            </div>
                        </div>
                        <div className="ml-4">
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                            99.9% uptime guarantee
                            </dt>
                            <dd className="mt-2 text-base text-gray-500">
                            Keeping your websites accessible online 24–7 is our top priority.
                            </dd>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            
                            <img src={CMS} alt="Website Installer" className="w-5 m-0"/>
                            </div>
                        </div>
                        <div className="ml-4">
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                            Website Installer
                            </dt>
                            <dd className="mt-2 text-base text-gray-500">
                            Offering you a way to launch your own site on the Internet within a few minutes.
                            </dd>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            
                            <img src={Secure} alt="SSL Certificate" className="w-5 m-0"/>
                            </div>
                        </div>
                        <div className="ml-4">
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                            Free SSL certificate
                            </dt>
                            <dd className="mt-2 text-base text-gray-500">
                            Have your SSL certificates installed automatically as soon as they have been issued with us.
                            </dd>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    </div>

)


export default Features
